import React from 'react'
import ReactDom from 'react-dom'
import { configure } from 'mobx'
import App from './app'

import './assets/global.sass'
import './assets/tailwind.sass.custom'

configure({
  enforceActions: 'never',
})

ReactDom.render(
  React.createElement(App),
  document.getElementById('root'),
)
