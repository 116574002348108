import React from 'react'
import { defineComponent } from '@firefox-pro-coding/react-composition-api'
import { ThemeProvider } from '@material-ui/core'

import { theme } from './theme'

import './index.sass'

interface Props {
  children?: React.ReactNode
}

const BasicLayout = defineComponent((props: Props) => () => (
  <div className="layout-box flex-col">
    <ThemeProvider theme={theme}>
      {props.children}
    </ThemeProvider>
  </div>
))

export default BasicLayout
