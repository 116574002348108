import { createMuiTheme } from '@material-ui/core'

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#22b0c3',
      contrastText: '#fff',
    },
    secondary: {
      main: '#1880b8',
    },
  },
})
