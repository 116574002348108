import api from "!../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../node_modules/css-loader/dist/cjs.js!../../node_modules/scoped-css-loader/index.js!../../node_modules/postcss-loader/dist/cjs.js!../../node_modules/sass-loader/dist/cjs.js!./index.sass";

var options = {"insert":"body"};

options.insert = "body";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};